import React, { Component } from 'react';

class Path extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            windowsPath: "",
            linuxPath: "",
            macPath: "",
            checked: true,
            isMacPath: false
        };
        this.handleWindowsPathChange = this.handleWindowsPathChange.bind(this)
        this.handleLinuxPathChange = this.handleLinuxPathChange.bind(this)
        this.showMacPath = this.showMacPath.bind(this)
    }


    handleWindowsPathChange(event) {
        const windowsPattern = /\\+/g;
        let inputValue = event.target.value;
        let linuxPath = inputValue.replaceAll(windowsPattern, "/");
        let macPath = "/Volumes" + inputValue.replaceAll(windowsPattern, "/");
        this.setState({
            linuxPath: linuxPath,
            macPath: macPath
        })
    }

    handleLinuxPathChange(event) {
        const linuxPattern = /\/+/g;
        let inputValue = event.target.value;
        let windowsPath = inputValue.replaceAll(linuxPattern, "\\").replaceAll(/^\\+/g, "\\\\");
        this.setState({windowsPath: windowsPath})
    }

    showMacPath(event) {
        if (event.target.value === "macPath") {
            this.setState({ isMacPath: true, checked: false })
        } else if (event.target.value === "linuxPath") {
            this.setState({ isMacPath: false, checked: true })
        }
    }

    render() {
        return (
            <div className="container">
                <h2>Convert Windows Path to UNIX</h2>
                <form>
                    <div className="form-group">
                        <input className="form-control" type="text" value={this.windowsPath} onChange={this.handleWindowsPathChange} placeholder="Example: \\server\share\folder" />
                    </div>
                </form>

                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="linux" name="path" value="linuxPath" onChange={this.showMacPath} checked={this.state.checked} />
                    <label className="form-check-label" for="linux">Linux Path</label>
                    <input className="form-check-input" type="radio" id="mac" name="path" value="macPath" onChange={this.showMacPath} />
                    <label className="form-check-label" for="mac">Mac Path</label>
                </div>
                <form>
                    <div className="form-group">
                        <input className="form-control" type="text" value={this.state.isMacPath ? this.state.macPath : this.state.linuxPath} placeholder="Example: /server/share/folder or /Volumes/server/share/folder" readOnly/>
                    </div>
                </form>

                <h2>Convert UNIX Path to Windows</h2>
                <form>
                    <div className="form-group">
                        <input className="form-control" type="text" value={this.linuxPath} onChange={this.handleLinuxPathChange} placeholder="Example: /server/share/folder" />
                    </div>
                </form>
                <form>
                    <div className="form-group">
                        <input className="form-control" type="text" value={this.state.windowsPath} placeholder="Example: \\server\share\folder" readOnly/>
                    </div>
                </form>
            </div>
        );
    }
}

export default Path;