import React from 'react';
// import logo from './logo.svg';
// import './App.css';
import Path from './Path.js';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
      </header> */}
      <Path />
    </div>
  );
}

export default App;
